'use strict'

//============================NAVIGÁCIÓ=========================================

const navLinks = document.querySelectorAll('.navigation__link');
const navCheckbox = document.querySelector('#navi-toggle')

function closeNav() {
  navCheckbox.checked = false;
};
//===============Navigáció bezárása, ha egy linkre katt===================
navLinks.forEach(navLink => navLink.addEventListener('click', closeNav ));

//================Az ismerjen meg gomb=========================

const headerBtn = document.querySelector('#header-button');
const sectionAbout = document.querySelector('#section-about');

headerBtn.addEventListener('click', (event)=> {
  event.preventDefault();
  sectionAbout.scrollIntoView({behavior: "smooth"})
});


//========================VIDEÓ==================================

const sectionVideo = document.querySelector('.section-video');
const videoContent = document.querySelector('.bg-video__content');
const videoTitle = document.querySelector('.video-title');

const observer = new IntersectionObserver(function(entries, observer) {
  entries.forEach(function(entry) {

    const {isIntersecting, intersectionRatio} = entry;

    if (isIntersecting) {
        console.log(intersectionRatio)
        if (intersectionRatio > 0.1 && intersectionRatio < 0.2) {
            videoContent.style.transform = 'scale(0.80)';
            videoTitle.style.opacity = '0'
        } else if(intersectionRatio > 0.2 && intersectionRatio < 0.3) {
            videoContent.style.transform = 'scale(0.85)';
            videoTitle.style.opacity = '0'
        } else if(intersectionRatio > 0.3 && intersectionRatio < 0.4) {
            videoContent.style.transform = 'scale(0.90)';
            videoTitle.style.opacity = '0'
        } else if(intersectionRatio > 0.4 && intersectionRatio < 0.5) {
            videoContent.style.transform = 'scale(0.95)';
            videoTitle.style.opacity = '0'
        } else if(intersectionRatio > 0.5 && intersectionRatio < 0.8) {
            videoContent.style.transform = 'scale(1)';
            videoTitle.style.opacity = '0'
        } else if(intersectionRatio > 0.8) {
            // videoContent.style.transform = 'scale(1)';
            videoTitle.style.transform = 'translate(-50%, -50%) scale(1)';
            videoTitle.style.opacity = '1'
        }
       
            // videoContent.style.transform = 'scale(1)';
            // videoTitle.style.transform = 'translate(-50%, -50%) scale(1)';
            // videoTitle.style.opacity = '1';
        
    } else {
      videoContent.style.transform = 'scale(0.75)';
      videoTitle.style.transform = 'translate(-50%, -50%) scale(0)';
      videoTitle.style.opacity = '0';
    }
  });
}, { threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.8] });

observer.observe(sectionVideo);


//=====================PROJECT SECTION===========================================

//=======================Kártyák renderelése========================

import cardData from '../data/cards.json';



function displayCards() {
  const container = document.getElementById('projects-container-cards');

  cardData.forEach((card) => {
    const cardHtml = `
      <div class="project-card">
        <div class="project-card-side project-card-side-front">
        <div class="project-card-img-container">
          <img class="project-card-image" src="${card.image_url}" alt="${card.project_name}">
          <h3 class="project-card-title" > ${card.project_name} </h3>
        </div>
          
          <p>További információkért helyezd az egérmutatót az elem fölé</p>
        </div>
        <div class="project-card-side project-card-side-back">
          <h4 class="project-card-details-title">A projekt részletei:</h4>
          <p class="project-card-details">${card.details}</p>
          <p class="project-card-location"><strong>Helyszín:</strong> ${card.location}</p>
          <p class="project-card-status"><strong>Statusz:</strong> ${card.status}</p>
          <p class="project-card-startsDate"><strong>Projekt kezdete:</strong> ${card.start_date}</p>
          <p calss="project-card-endDate"><strong>Befejezés dátuma:</strong> ${card.end_date}</p>
        </div>
      </div>
    `;
    container.insertAdjacentHTML('beforeend', cardHtml);
  });
}

displayCards();




//=====================Kártya mozgatás============================


window.addEventListener('load', ()=> document.getElementById('btn-left').style.display = 'none')
const cards = document.querySelector('.projects-container-cards');
const cardButtons = document.querySelectorAll('.projects-container-btn');
const card = document.querySelector('.project-card');

const cardWidth = card.offsetWidth;
const cardStyles = getComputedStyle(card);
const cardMargins = parseInt(cardStyles.marginLeft) + parseInt(cardStyles.marginRight);

let startPosition = null;
let currentPosition = 0;


cards.addEventListener('touchstart', event => {
  startPosition = event.touches[0].clientX;
});

cards.addEventListener('touchmove', event => {
  if (startPosition !== null) {
    const currentPositionX = event.touches[0].clientX;
    const diffX = startPosition - currentPositionX;
    currentPosition -= diffX;
    cards.style.transform = `translateX(${currentPosition}px)`;
    startPosition = currentPositionX;
  }
  // event.preventDefault()
});

cards.addEventListener('touchend', event => {
  startPosition = null;
});


cardButtons.forEach( button => {
  button.addEventListener('click', event => {
    const { id } = event.target;
    const viewportWidth = window.innerWidth
    const visibleCards = Math.floor(viewportWidth / ( cardWidth + cardMargins ) );
   
    switch (id) {
      case 'btn-left':
        // currentPosition += visibleCards * (cardWidth + cardMargins)
        currentPosition += visibleCards >= 1 ? visibleCards * (cardWidth + cardMargins) : (cardWidth + cardMargins);
        if (currentPosition > 0 ) {
          currentPosition = 0
          document.getElementById('btn-left').style.display = 'none'
        }
        cards.style.transform = `translateX(${currentPosition}px)`;
        document.getElementById('btn-right').style.display = 'block'
        break;
      case 'btn-right':
        const maxPosition = -1 * (cards.scrollWidth - cards.clientWidth);
        // currentPosition -= visibleCards * (cardWidth + cardMargins);
        currentPosition -= visibleCards >= 1 ? visibleCards * (cardWidth + cardMargins) : (cardWidth + cardMargins);
        if (currentPosition < maxPosition) {
          currentPosition = maxPosition-cardMargins;
          document.getElementById('btn-right').style.display = 'none'
        }
        cards.style.transform = `translateX(${currentPosition}px)`;
        document.getElementById('btn-left').style.display = 'block'
        break;

      default:
        break;
    }

  });
});
[
  {
    "image_url": "https://images.unsplash.com/photo-1465804575741-338df8554e02?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80",
    "project_name": "Lakóház Felújítás",
    "start_date": "2022-01-01",
    "end_date": "2022-06-30",
    "status": "Befejezett",
    "details": "Egy 3 hálószobás ház felújítása az belső design frissítéséhez és a funkcionalitás javításához. A munkálatok tartalmazták a konyha és fürdőszoba felújítását, új padlóburkolat és világítás beépítését, valamint kültéri kertrendezést.",
    "location": "Los Angeles, USA"
  },
  {
    "image_url": "https://images.unsplash.com/photo-1595980542930-9eea66620834?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
    "project_name": "Irodaház Design",
    "start_date": "2022-03-15",
    "end_date": "2022-12-31",
    "status": "Folyamatban",
    "details": "Egy 10 emeletes irodaház tervezése modern, minimalistára hangolva. Tartalmazni fog fenntartható anyagokat, természetes világítást és nyitott tervű munkaterületeket.",
    "location": "London, UK"
  },
  {
    "image_url": "https://images.unsplash.com/photo-1488972685288-c3fd157d7c7a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "project_name": "Nyilvános Park",
    "start_date": "2022-05-01",
    "end_date": "2023-06-30",
    "status": "Tervezés",
    "details": "Egy nyilvános park tervezése a fenntarthatóságra és a közösségi elkötelezettségre fókuszálva. Tartalmazni fog zöld területeket, sétálóutakat és egy játszóteret.",
    "location": "Sydney, Ausztrália"
  },
  {
    "image_url": "https://images.unsplash.com/photo-1493397212122-2b85dda8106b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80",
    "project_name": "Kereskedelmi épület",
    "start_date": "2022-01-01",
    "end_date": "2022-12-31",
    "status": "Folyamatban",
    "details": "Új, öt emeletes irodaház tervezése modern kialakítással és hatékony térhasználattal. A tetőkert, a természetes megvilágítás és az energiatakarékos rendszerek lesznek az épület főbb jellemzői.",
    "location": "Dubai, Egyesült Arab Emírségek"
  },
  {
    "image_url": "https://images.unsplash.com/photo-1435575653489-b0873ec954e2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "project_name": "Étterem tervezés",
    "start_date": "2022-02-15",
    "end_date": "2022-08-31",
    "status": "Befejezve",
    "details": "Új étterem tervezése modern, ipari stílusban. Az épület nyitott konyhát, kültéri ülőhelyeket és bárpultot fog magában foglalni.",
    "location": "Toronto, Kanada"
  },
  {
    "image_url": "https://images.unsplash.com/photo-1487958449943-2429e8be8625?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "project_name": "Többcsaládos lakások",
    "start_date": "2022-04-01",
    "end_date": "2023-06-30",
    "status": "Tervezés",
    "details": "Új lakóépület tervezése többcsaládos házak számára fenntartható életmódra összpontosítva. A közösségi terek, zöldtetők és az energiatakarékos rendszerek lesznek a főbb jellemzői az épületnek.",
    "location": "Stockholm, Svédország"
  },
  {
    "image_url": "https://images.unsplash.com/photo-1507097489474-c9212a8f8597?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80",
    "project_name": "Múzeumi kiállítás tervezése",
    "start_date": "2022-05-15",
    "end_date": "2022-10-31",
    "status": "Befejezett",
    "details": "Egy helyi múzeum interaktív kiállításának tervezése a város történelmének bemutatására. A jellemzők közé tartoznak a multimédiás bemutatók, a kézzelfogható tevékenységek és az élvezetes történetmesélés.",
    "location": "Párizs, Franciaország"
  },
  {
    "image_url": "https://images.unsplash.com/photo-1511818966892-d7d671e672a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80",
    "project_name": "Történelmi épület felújítása",
    "start_date": "2022-07-01",
    "end_date": "2023-03-31",
    "status": "Folyamatban",
    "details": "Egy történelmi épület felújítása az épület architekturális jellemzőinek megőrzése és a modern használatra való alkalmazkodás érdekében. A munka magában foglalja a szerkezeti fejlesztéseket, az épület homlokzatának restaurálását.",
    "location": "Boston, USA"
  },
  {
    "image_url": "https://images.unsplash.com/photo-1451976426598-a7593bd6d0b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "project_name": "Belsőépítészet",
    "start_date": "2022-09-01",
    "end_date": "2022-11-30",
    "status": "Befejezett",
    "details": "Egy luxus apartman belsőjének tervezése a kényelem és az elegancia fókuszában. Tartalmaz személyre szabott bútorokat, magas minőségű burkolatokat és gondosan összeválogatott műalkotásokat.",
    "location": "Hongkong, Kína"
  },
  {
    "image_url": "https://images.unsplash.com/photo-1496564203457-11bb12075d90?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1250&q=80",
    "project_name": "Nyilvános tér tervezés",
    "start_date": "2023-01-01",
    "end_date": "2023-12-31",
    "status": "Tervezés",
    "details": "Egy új nyilvános park tervezése a fenntarthatóság és az elérhetőség fókuszában. Tartalmaz sétányokat, közösségi kerteket és közterületi művészeti alkotásokat.",
    "location": "Sydney, Ausztrália"
  }
]
